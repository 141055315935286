<template>
  <div class="bo-selector bo--selector-store">
    <i class="fas fa-store-alt" />
    <MultiSelect
        class="bo-fullwidth"
        :options="globalStore.getters.availableStores"
        :optionLabel="localize.field('name')"
        placeholder="Store"
        optionValue="id"
        v-model="selectedStores"
    >
    </MultiSelect>
  </div>
</template>
<script>
import {computed, defineComponent, onMounted, reactive, watch} from "vue";
import {globalStore} from "@/stores/globalstore";
import {authStore} from "@/stores/authstore";
import filters from "@/helpers/filters";
import useLocalization from "@/hooks/localization";
export default defineComponent({
  methods: {
    filters() {
      return filters
    }
  },
  setup() {
    const localize = useLocalization();

    const selectedStores = computed({
      get: () => { return globalStore.state.selected.storeIds; },
      set: (v) => { globalStore.dispatch('setStoreIds', v); }
    });

    return {
      globalStore,
      localize,
      selectedStores
    }
  }
})
</script>

<!--<template>
  <div class="bo-selector bo--selector-store">
    <i class="fas fa-store-alt" />
    <MultiSelect
      class="bo-fullwidth"
      v-model="selectedStores.value"
      :options="stores.value"
      optionLabel="name_fr"
      placeholder="Store"
      dataKey="id"
      optionValue="id"
    >
    </MultiSelect>
  </div>
  <!- Stores : {{ selectedStores.value }} ->
</template>

<script>
import { defineComponent, onMounted, reactive, watch } from "vue";
import { globalStore } from "@/stores/globalstore";
import {authStore} from "@/stores/authstore";

export default defineComponent({
  setup() {
    const stores = reactive([]);
    const selectedStores = reactive([]);

    const refreshStores = async () => {
      stores.value = (await globalStore.dispatch("fetchStores"))
          .filter((s) => globalStore.state.selected.entityIds.some((id) => id === s.entity_id) &&
                         (authStore.state.globalUser?.is_admin || s.resupply)
      );

      selectedStores.value = stores.value.map((x) => x.id);
    };

    watch(
      () => selectedStores.value,
      (newVal, oldVal) => {
        globalStore.dispatch("setStoreIds", newVal);
      }
    );

    watch(
      () => globalStore.state.selected.entityIds,
      async (newVal, oldVal) => {
        await refreshStores();
      }
    );

    onMounted(async () => {
      // await refreshStores();
    });

    return { stores, selectedStores };
  },
});
</script>
-->