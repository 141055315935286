
import useProvider from "@/hooks/provider";
import Organization from "@/models/organization";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";

import LoginAsDialog from "@/components/OrganizationSystem/LoginAsDialog.vue";
import { authStore } from "@/stores/authstore";
import GlobalUser from "@/models/globaluser";

export default defineComponent({
  components: {
    LoginAsDialog,
  },
  setup() {
    const provider = useProvider();
    const loginAsDialog = ref();

    const visible = computed(
      () => loggedAs.organization?.is_system || loggedAs.active
    );

    const loggedAs = reactive({
      active: false,
      globalUser: ref<GlobalUser | null>(),
      organization: ref<Organization | null>(),
    });

    const loginAsButtonClicked = () => {
      loginAsDialog.value.openDialog();
    };

    const loginAsClicked = async (user: any) => {
      await authStore.dispatch("loginAs", user.global_user_uid);
    };

    const logoutAsButtonClicked = async () => {
      await authStore.dispatch("logoutAs");
    };

    const refreshLoggedAs = async () => {
      loggedAs.globalUser = authStore.state.globalUser;
      loggedAs.organization = authStore.state.globalUser
        ? await provider.organization.fetchOrganization()
        : null;
      loggedAs.active =
        (await authStore
          .dispatch("getSystemAccessTokenAsync")
          .catch((e) => {})) != undefined &&
        loggedAs.globalUser != null &&
        loggedAs.organization != null;
    };

    watch(
      () => authStore.state.globalUser,
      async (newVal, oldVal) => {
        await refreshLoggedAs();
      }
    );

    onMounted(async () => {
      await refreshLoggedAs();
    });

    return {
      visible,
      loggedAs,
      loginAsButtonClicked,
      loginAsDialog,
      loginAsClicked,
      logoutAsButtonClicked,
    };
  },
});
