
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
  inject,
  getCurrentInstance,
} from "vue";
import { authStore } from "@/stores/authstore";
import GlobalUser from "@/models/globaluser";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import RouteHelper from "@/helpers/routehelper";
import EntitySelector from "@/components/EntitySelector.vue";
import StoreSelector from "@/components/StoreSelector.vue";
import useBreakpoints from "./compositions/useBreakpoints";
import useProvider from "@/hooks/provider";
import Listing from "./models/listing";
import useLocalization from "./hooks/localization";
import { globalStore } from "./stores/globalstore";
import { usePrimeVue } from "primevue/config";
import stringFilter from "@/helpers/filters";

const packageJson = require("../package.json");

import { groupBy } from "lodash";
import ListingViewModel from "./models/view/listingviewmodel";

import OrganizationSystemBanner from "@/components/OrganizationSystem/OrganizationSystemBanner.vue";
import filters from '@/helpers/filters';
import { useSettingStore } from '@/stores/settingstore';
import Organization from "./models/organization";
import axios from 'axios';

export default defineComponent({
  components: {
    EntitySelector,
    StoreSelector,
    OrganizationSystemBanner,
  },
  //inject: ["usersession"],

  setup() {
    const app = getCurrentInstance();
    const router = useRouter();
    const i18n = useI18n();
    const provider = useProvider();
    const localization = useLocalization();
    const primevue = usePrimeVue();
    const settingStore = useSettingStore();
    settingStore.dispatch('load');

    app!.appContext.config.globalProperties.i18n = i18n;
    app!.appContext.config.globalProperties.$t = i18n.t;

    const t = i18n.t;
    const { width, type } = useBreakpoints();
    const version = packageJson.version;
    const route = router.currentRoute;
    const menuShown = ref(true);
    const auth = {
      globalUser: ref<GlobalUser | null>(),
      organization: ref<Organization | null>(),
    };

    const listings = ref<ListingViewModel[]>();

    const loadListings = async () => {
      listings.value = await provider.dashboard.getListingViewModels();

      listings.value?.sort((a, b) =>
        (localization.localize(a.globalCategory) ?? "").localeCompare(
          localization.localize(b.globalCategory) ?? ""
        )
      );
    };

    class SubItem {
      icon?: string;
      iconPath?: string;
      iconHtml?: string;
      link?: string;
      color?: string;
      name?: string;
      visible: boolean = true;
      group?: string;

      constructor(init?: Partial<SubItem>) {
        Object.assign(this, init);
      }
    }

    //const usersession = inject('usersession') as Object;
    //usersession.auth = auth;
    router.afterEach((to, from, next) => {
      // Closes the menu on mobile after navigation
      if (type.value == "xs" || type.value == "sm") {
        menuShown.value = false;
      }
    });
    const sideMenu = computed(() => {
      switch (route.value.name) {
        // Dashboards
        case "dashboard":
        case "dashboardca":
        case "dashboardreportz":
        case "dashboarditemlevels":
        case "dashboardcaglobalusers":
        case "dashboardpointglobalusers":
        case "dashboardsalesdetails":
        case "dashboardlisting":
          return {
            title: "Dashboard",
            subItems: [
              new SubItem({
                icon: "chart-line",
                link: "dashboard/ca",
                color: "redcarmin",
                name: filters.capitalizeFirstLetter(i18n.t("turnover")),
              }),
              new SubItem({
                icon: "file-invoice",
                link: "dashboard/reportz",
                color: "pumpkin",
                name: filters.capitalizeFirstLetter(i18n.t("report_z")),
              }),
              new SubItem({
                icon: "sitemap",
                link: "dashboard/itemlevels",
                color: "gold",
                name: filters.capitalizeFirstLetter(i18n.t("by_item_levels")),
              }),
              new SubItem({
                icon: "users",
                link: "dashboard/caglobalusers",
                color: "bluesky",
                name: filters.capitalizeFirstLetter(i18n.t("ca_global_users")),
              }),
              new SubItem({
                icon: "user-clock",
                link: "dashboard/pointglobalusers",
                color: "blueroyal",
                name: filters.capitalizeFirstLetter(i18n.t("point_global_users")),
              }),
              new SubItem({
                icon: "shopping-bag",
                link: "dashboard/salesdetails",
                color: "greenforest",
                name: filters.capitalizeFirstLetter(i18n.t("sales_details")),
              }),
            ].concat(
              (listings.value?.map((listing) => {
                return new SubItem({
                  group: localization.localize(listing.globalCategory) ?? undefined,
                  iconPath: listing.listing?.icon_azure_path,
                  link: "dashboard/listing/" + listing.listing?.id,
                  name: localization.localize(listing.listing) ?? undefined,
                });
              }) as []) ?? []
            ),
          };
        // Items
        case "items":
        case "item-edition":
        case "item-levels":
        case "item-level-edition":
        case "item-categories":
        case "item-category-edition":
        case "item-history":
        case "item-history-list":
        case "item-history-view":
        case "item-ranks":
        case "item-rank-edition":
        case "propositions":
        case "proposition-edition":
        case "stocks":
        case "stock-item-edition":
          return {
            title: filters.cflt("items"),
            subItems: [
              new SubItem({
                icon: "box-open",
                link: "items",
                color: "redcarmin",
                name: filters.cflt("items"),
              }),
              new SubItem({
                icon: "sitemap",
                link: "item-levels",
                color: "blueroyal",
                name: filters.cflt("item_levels"),
              }),
              new SubItem({
                icon: "sitemap",
                link: "item-categories",
                color: "purple",
                name: filters.cflt("item_categories"),
              }),
              new SubItem({
                icon: "link",
                link: "propositions",
                color: "greenfield",
                name: filters.cflt("propositions"),
              }),
              /* {
                icon: "table-cells",
                link: "stores?from=items",
                color: "blueroyal",
                name: filters.cflt("matrices"),
              }, */
              new SubItem({
                icon: "cubes",
                link: "stocks",
                color: "gold",
                name: filters.cflt("stocks"),
              }),
              new SubItem({
                icon: "history",
                link: "item-history",
                color: "bluesky",
                name: filters.cflt("item_history"),
              }),
              new SubItem({
                icon: "sort-numeric-down",
                link: "item-ranks",
                color: "greenforest",
                name: filters.cflt("ranks"),
              }),

              /*
               {
                icon: "crop",
                link: "items/resize",
                color: "greenfield",
                name: "Resize",
              },
              {
                icon: "th-large",
                link: "clients",
                color: "pumpkin",
                name: "Matrices",
              },
              {
                icon: "sitemap",
                link: "suppliers",
                color: "gold",
                name: "Hiérarchies",
              },
              {
                icon: "list",
                link: "sales",
                color: "greenfield",
                name: "Catégories",
              },
              {
                icon: "link",
                link: "expenses",
                color: "greenforest",
                name: "Propositions",
              },
              {
                icon: "tags",
                link: "users",
                color: "bluesky",
                name: "Listes de Prix",
              },
              {
                icon: "boxes",
                link: "reporting",
                color: "blueroyal",
                name: "Inventaires",
              },*/
            ],
            // actions: [
            //   {
            //     icon: "plus",
            //     link: "items",
            //     style: "color-coal background-silver",
            //     name: "Nouvel article",
            //   },
            // ],
          };
        // Customers
        case "customers":
        case "customer-edition":
        case "customer-history":
        case "customer-category-edition":
        case "customer-categories":
          return {
            title: filters.cflt("customers"),
            subItems: [
              new SubItem({
                icon: "user-tie",
                link: "customers",
                color: "redcarmin",
                name: filters.cflt("customers"), // TODO : Capitalize
              }),
              new SubItem({
                icon: "sitemap",
                link: "customer-categories",
                color: "gold",
                name: i18n.t("customer_categories"),
              }),
            ]
          };
        // Suppliers
        case "suppliers":
        case "supplier-edition":
        case "supplier-history":
        case "supplier-account":
          return {
            title: filters.cflt("suppliers"),
            subItems: [
              new SubItem({
                icon: "person-dolly",
                link: "suppliers",
                color: "redcarmin",
                name: filters.cflt("suppliers"),
              }),
            ]
          };
        // Sales
        case "sale-quotes":
        case "sale-quotes-view":
        case "sale-orders":
        case "sale-orders-view":
        case "sale-deliveries":
        case "sale-deliveries-view":
        case "sale-invoices":
        case "sale-invoices-view":
        case "sale-edition":
        case "sale-creation":
        case "sale-creation-from":
          return {
            title: stringFilter.capitalizeFirstLetter(i18n.t("sales")),
            subItems: [
              new SubItem({
                icon: "fa fa-circle-question",
                link: "sale-quotes",
                color: "gold",
                name: stringFilter.capitalizeFirstLetter(i18n.t("sale_quotes")),
              }),
              new SubItem({
                icon: "fa fa-clock",
                link: "sale-orders",
                color: "bluesky",
                name: stringFilter.capitalizeFirstLetter(i18n.t("sale_orders")),
              }),
              new SubItem({
                icon: "fa fa-file-circle-check",
                link: "sale-deliveries",
                color: "greenforest",
                name: stringFilter.capitalizeFirstLetter(i18n.t("sale_deliveries")),
              }),
              new SubItem({
                icon: "fa fa-file-invoice-dollar",
                link: "sale-invoices",
                color: "redcarmin",
                name: stringFilter.capitalizeFirstLetter(i18n.t("sale_invoices")),
              }),
            ],
          };
        // Purchases
        case 'purchase-quotes':
        case 'purchase-quotes-view':
        case 'purchase-orders':
        case 'purchase-orders-view':
        case "purchase-deliveries":
        case 'purchase-deliveries-view':
        case 'purchase-invoices':
        case 'purchase-invoices-view':
        case "purchase-edition":
        case "purchase-creation":
          return {
            title: stringFilter.capitalizeFirstLetter(i18n.t("purchases")),
            subItems: [
              new SubItem({
                icon: "fa fa-clipboard-check",
                link: "purchase-quotes",
                color: "redcarmin",
                name: stringFilter.capitalizeFirstLetter(i18n.t("purchase_quotes"))
              }),
              new SubItem({
                icon: "fa fa-bullhorn",
                link: "purchase-orders",
                color: "gold",
                name: stringFilter.capitalizeFirstLetter(i18n.t("purchase_orders"))
              }),
              new SubItem({
                icon: "fa fa-truck",
                link: "purchase-deliveries",
                color: "greenfield",
                name: stringFilter.capitalizeFirstLetter(i18n.t("purchase_deliveries"))
              }),
              new SubItem({
                icon: "fa fa-file-alt",
                link: "purchase-invoices",
                color: "blueroyal",
                name: stringFilter.capitalizeFirstLetter(i18n.t("purchase_invoices"))
              }),
            ]
          }
        // Store Transfers
        case 'storetransfer-direct':
        case 'storetransfer-direct-edition':
        case 'storetransfer-direct-creation':
        case 'storetransfer-direct-view':
        case 'storetransfer-flow-validating':
        case 'storetransfer-flow-validating-view':
        case 'storetransfer-flow-preparing':
        case 'storetransfer-flow-preparing-view':
        case 'storetransfer-flow-refused':
        case 'storetransfer-flow-refused-view':
        case 'storetransfer-flow-expedited':
        case 'storetransfer-flow-expedited-view':
        case 'storetransfer-flow-delivered':
        case 'storetransfer-flow-delivered-view':
        case 'storetransfer-flow-edition':
        case 'storetransfer-flow-edition-view':
        case 'storetransfer-flow-creation':
        case 'storetransfer-flow-creation-view':
          return {
            title: stringFilter.capitalizeFirstLetter(i18n.t("storetransfer_movements")),
            subItems: [
              new SubItem({
                icon: "fa fa-long-arrow-right",
                link: "storetransfer-direct",
                color: "bluesky",
                name: stringFilter.capitalizeFirstLetter(i18n.t("storetransfer_direct"))
              }),
              new SubItem({
                group: stringFilter.capitalizeFirstLetter(i18n.t("storetransfer_flow")),
                icon: "fal fa-spinner",
                link: "storetransfer-flow-validating",
                color: "greenfield",
                name: stringFilter.capitalizeFirstLetter(i18n.t("storetransfer_flow_validating"))
              }),
              new SubItem({
                group: stringFilter.capitalizeFirstLetter(i18n.t("storetransfer_flow")),
                icon: "fal fa-thumbs-up",
                link: "storetransfer-flow-preparing",
                color: "blueroyal",
                name: stringFilter.capitalizeFirstLetter(i18n.t("storetransfer_flow_preparing"))
              }),
              new SubItem({
                group: stringFilter.capitalizeFirstLetter(i18n.t("storetransfer_flow")),
                icon: "fas fa-truck",
                link: "storetransfer-flow-expedited",
                color: "purple",
                name: stringFilter.capitalizeFirstLetter(i18n.t("storetransfer_flow_expedited"))
              }),
              new SubItem({
                group: stringFilter.capitalizeFirstLetter(i18n.t("storetransfer_flow")),
                icon: "fa fa-box-circle-check",
                link: "storetransfer-flow-delivered",
                color: "greenforest",
                name: filters.cflt("storetransfer_flow_delivered")
              }),
              new SubItem({
                group: filters.cflt("storetransfer_flow"),
                icon: "fas fa-ban",
                link: "storetransfer-flow-refused",
                color: "redcarmin",
                name: filters.cflt("storetransfer_flow_refused")
              }),
            ]
          }
        case "storetransfer-stock-correction":
        case "storetransfer-stock-global-reset":
        case "storetransfer-stock-global-transcription":
          return {
            subItems: [
              new SubItem({
                icon: "fa fa-edit",
                link: "storetransfer-stock-correction",
                color: "gold",
                name: filters.cflt("storetransfer_stock_correction")
              }),
              new SubItem({
                group: filters.cflt("storetransfer_stock_global"),
                icon: "fa fa-eraser",
                link: "storetransfer-stock-global-reset",
                color: "redcarmin",
                name: filters.cflt("storetransfer_stock_global_reset")
              }),
              new SubItem({
                group: filters.cflt("storetransfer_stock_global"),
                icon: "fa fa-pen-square",
                link: "storetransfer-stock-global-transcription",
                color: "purple",
                name: filters.cflt("storetransfer_stock_global_transcription")
              }),
            ]
          }
        // Settings
        case "settings":
        case "my-organization":
        case "global-users":
        case "global-user-edition":
        case "glory-user-types":
        case "glory-user-type-edition":
        case "stores":
        case "store-edition":
        case "store-printers-edition":
        case "entities":
        case "entity-edition":
        case "pos":
        case "pos-edition":
        case "api":
        case "changehistory":
        case "webhooks":
        case "webhook-edition":
        case "printers":
        case "printer-edition":
        case "price-lists":
        case "price-list-edition":
        case "licenses":
          return {
            title: i18n.t("settings"),
            subItems: [
              new SubItem({
                icon: "briefcase",
                link: "my-organization",
                color: "redcarmin",
                name: i18n.t("my-organization"),
              }),
              new SubItem({
                icon: "tablet-alt",
                link: "pos",
                color: "pumpkin",
                name: "POS",
              }),
              new SubItem({
                icon: "users",
                link: "global-users",
                color: "greenfield",
                name: "Users",
              }),
              new SubItem({
                icon: "coins",
                link: "glory-user-types",
                color: "greenforest",
                name: "Glory user types",
              }),
              new SubItem({
                icon: "store",
                link: "stores",
                color: "bluesky",
                name: "Stores",
              }),
              new SubItem({
                icon: "building",
                link: "entities",
                color: "blueroyal",
                name: "Entities",
              }),
              new SubItem({
                icon: "list-ul",
                link: "price-lists",
                color: "purple",
                name: i18n.t("price-lists"),
              }),
              new SubItem({
                icon: "print",
                link: "printers",
                color: "gold",
                name: filters.cflt("printers"),
              }),
              new SubItem({
                icon: "list-ul",
                link: "licenses",
                color: "bluesky",
                name: i18n.t("licenses"),
                visible: auth.organization.value?.is_system ?? false,
              }),
              new SubItem({
                icon: "code-branch",
                link: "api",
                color: "greenfield",
                name: "API",
              }),
              new SubItem({
                icon: "pencil",
                link: "webhooks",
                color: "steel",
                name: "Webhooks",
              }),
              new SubItem({
                icon: "certificate",
                link: "changehistory",
                color: "yellow",
                name: "Change history",
              }),
              /* {
                group: i18n.t("matrices"),
                iconHtml: `
                    <span class="fa-stack">
                        <i class="fas fa-table-cells"></i>
                        <i class="fas fa-store fa-stack-1x color-bluesky"
                           style="font-size: 0.8rem; background: transparent; top:0.6em; left: 0.5em" />
                    </span>
                `,
                link: "matrices/globals",
                color: "purple",
                name: stringFilter.capitalizeFirstLetter(i18n.t("matrices_globals"))
              },
              {
                group: i18n.t("matrices"),
                iconHtml: `
                    <span class="fa-stack">
                        <i class="fas fa-table-cells"></i>
                        <i class="fas fa-cash-register fa-stack-1x color-redcarmin"
                           style="font-size: 0.8rem; background: transparent; top:0.6em; left: 0.5em" />
                    </span>
                `,
                link: "matrices/centralregisters",
                color: "purple",
                name: stringFilter.capitalizeFirstLetter(i18n.t("matrices_centralregisters"))
              },
              {
                group: i18n.t("matrices"),
                iconHtml: `
                    <span class="fa-stack">
                        <i class="fas fa-table-cells"></i>
                        <i class="fas fa-tablet-button fa-stack-1x color-greenfield"
                           style="font-size: 0.8rem; background: transparent; top:0.6em; left: 0.5em" />
                    </span>
                `,
                link: "matrices/smartkiosk",
                color: "blueroyal",
                name: stringFilter.capitalizeFirstLetter(i18n.t("matrices_smartkiosk"))
              },
              {
                group: i18n.t("matrices"),
                iconHtml: `
                    <span class="fa-stack">
                        <i class="fas fa-table-cells"></i>
                        <i class="fas fa-arrow-pointer fa-stack-1x color-lightcoral"
                           style="font-size: 0.8rem; background: transparent; top:0.6em; left: 0.5em" />
                    </span>
                `,
                link: "matrices/clickandcollect",
                color: "purple",
                name: stringFilter.capitalizeFirstLetter(i18n.t("matrices_clickandcollect"))
              } */
            ],
          };
        default:
          return { title: "", subItems: [] };
      }
    });

    onMounted(async () => {
      menuShown.value = !["xs", "sm"].includes(type.value);
    });

    const globalUserWatcher = watch(
      () => authStore.state.globalUser,
      async (newVal, oldVal) => {
        console.log("global user changed", newVal, oldVal);
        auth.globalUser.value = newVal;
        auth.organization.value = newVal ? await provider.organization.fetchOrganization() : null;

        loadPrimeLocale();
        if (newVal) await loadListings(); else listings.value = undefined;
      }
    );

    const logout = async () => {
      await authStore.dispatch("logout");
      await globalStore.dispatch("clear");
      await router.push({ name: "login" });
    };

    const loadPrimeLocale = () => {
      if (primevue.config.locale) {
        switch (i18n.locale.value) {
          case "fr":
            primevue.config.locale.startsWith = "Commence par";
            primevue.config.locale.contains = "Contient";
            primevue.config.locale.notContains = "Ne contient pas";
            primevue.config.locale.endWith = "Termine par";
            primevue.config.locale.equals = "Égal";
            primevue.config.locale.notEquals = "N'est pas égal";
            primevue.config.locale.noFilter = "Aucun filtre";
            break;
          case "nl":
          case "en":
            primevue.config.locale.startsWith = "Starts with";
            primevue.config.locale.contains = "Contains";
            primevue.config.locale.notContains = "Not contains";
            primevue.config.locale.endWith = "Ends with";
            primevue.config.locale.equals = "Equals";
            primevue.config.locale.notEquals = "Not equals";
            primevue.config.locale.noFilter = "No Filter";
            break;
        }
      }
    };

    const changeLanguage = async (language: string) => {
      i18n.locale.value = language;
      //axios.defaults.headers.common['Accept-Language'] = language;
      router.push({ params: { locale: language } });
      loadPrimeLocale();
    };

    const getLink = (path: string) => RouteHelper.getLink(path);

    const toggleMenu = () => {
      menuShown.value = !menuShown.value;
    };

    return {
      logout,
      changeLanguage,
      auth,
      globalUserWatcher,
      getLink,
      version,
      route,
      sideMenu,
      toggleMenu,
      type,
      menuShown,
      t,
      groupBy,
    };
  },
});
