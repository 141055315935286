
import { defineComponent, ref } from "vue";

import ElementsEmptyIndicator from "@/components/EmptyIndicator.vue";

import useProvider from "@/hooks/provider";

export default defineComponent({
  components: {
    ElementsEmptyIndicator,
  },
  emits: ["closed", "loginAsClicked"],
  setup(props, { emit }) {
    const displayDialog = ref(false);
    const searchText = ref<string>("");
    const provider = useProvider();
    const users = ref<any[]>([]);
    const loading = ref<boolean>(false);

    const closeDialog = () => {
      if (displayDialog.value) {
        displayDialog.value = false;
        emit("closed");
      }
    };

    const openDialog = () => {
      displayDialog.value = true;
    };

    const search = async () => {
      if (!searchText.value) return;

      loading.value = true;

      users.value =
        await provider.globalUser.fetchGlobalUsersFromOrganizationSystem(searchText.value);

      loading.value = false;
    };

    const loginAs = (user: any) => {
      console.log("loginAs", user);

      emit("loginAsClicked", user);

      closeDialog();
    };

    return {
      displayDialog,
      closeDialog,
      openDialog,
      search,
      searchText,
      loading,
      loginAs,
      users,
    };
  },
});
