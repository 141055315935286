<template>
  <div class="bo-selector bo--selector-store">
    <i class="fas fa-building" />
    <MultiSelect
        class="bo-fullwidth"
        :options="globalStore.getters.availableEntities"
        optionLabel="name"
        placeholder="Entity"
        optionValue="id"
        v-model="selectedEntities"
    >
    </MultiSelect>
  </div>
</template>
<script>
import {computed, defineComponent, onMounted, reactive, watch} from "vue";
import {globalStore} from "@/stores/globalstore";
import {authStore} from "@/stores/authstore";
export default defineComponent({
  setup() {

    const selectedEntities = computed({
      get: () => globalStore.state.selected.entityIds,
      set: (v) => { globalStore.dispatch('setEntityIds', v); }
    });

    return {
      globalStore,
      selectedEntities
    }
  }
})
</script>

<!-- <template>


  <!- Entities : {{ selectedEntities.value }}
</template>

<script>
import EntityProvider from "@/providers/entityprovider";
import { defineComponent, onMounted, reactive, watch } from "vue";
import { globalStore } from "@/stores/globalstore";
import { authStore } from "@/stores/authstore";

export default defineComponent({
  setup() {
    const entities = reactive([]);

    const selectedEntities = reactive([]);

    watch(
      () => selectedEntities.value,
      (newVal, oldVal) => {
        globalStore.dispatch("setEntityIds", newVal);
      }
    );

    const refreshEntities = async () => {
      entities.value = await new EntityProvider().fetchEntities();
      selectedEntities.value = entities.value.map((x) => x.id);
    };

    watch(
      () => authStore.state.globalUser,
      async (newVal, oldVal) => {
        await refreshEntities();
      }
    );

    onMounted(async () => {
      await refreshEntities();
    });

    return {
      entities,
      selectedEntities,
    };
  },
});
</script> -->
