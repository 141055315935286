import BaseProvider from "./baseprovider";
import { PagedParams } from '@/models/paged/pagedparams';
import StorePrinter from '@/models/storeprinter';
import PagedStorePrinters from '@/models/paged/pagedstoreprinters';

export default class StorePrinterProvider extends BaseProvider {
     public async fetchStorePrinters(payload: {
        entityIds?: number[],
        storeIds?: number[],
        zoneIds?: number[],
        printerIds?: number[],
        printerModelIds?: number[],
        storePrinterIds?: number[],
        storePrinterUids?: string[]
    }): Promise<StorePrinter[]> {
        let p: String = '';
        p = p.addParams("entityIds", payload.entityIds, true);
        p = p.addParams("storeIds", payload.storeIds, true);
        p = p.addParams("zoneIds", payload.zoneIds, true);
        p = p.addParams("printerIds", payload.printerIds, true);
        p = p.addParams("printerModelIds", payload.printerModelIds, true);
        p = p.addParams("storePrinterIds", payload.storePrinterIds, true);
        p = p.addParams("storePrinterUids", payload.storePrinterUids, true);
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV1()).get(`storeprinters${p}`);

        return response.data as StorePrinter[];
    }

    public async fetchPagedStorePrinters(
        entityIds: number[],
        storeIds: number[],
        pagedParams: PagedParams
    ): Promise<PagedStorePrinters> {
        let p: String = '';
        p = p.addParams("entityIds", entityIds, true);
        p = p.addParams("storeIds", storeIds, true);
        p = p.addPagedParams(pagedParams, {
            pageNumber: 1,
            pageSize: 25,
            sortField: 'name',
            sortOrder: 'asc'
        });
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV1()).get(`storeprinters${p}`);

        return response.data as PagedStorePrinters;
    }

    public async saveStorePrinter(storePrinter: StorePrinter): Promise<StorePrinter> {
        console.log ('S: ', typeof storePrinter);
        const response = await (await this.getApiV1()).post(`/storeprinters/`, storePrinter.toJson());
        const savedStorePrinter = this.getStorePrinter(response.data);
        return savedStorePrinter;
    }

    private getStorePrinter(data: any): StorePrinter {
        const s = new StorePrinter();
        s.fromJson(data);
        return s;
    }
}