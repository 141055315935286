import AuthProvider from "@/providers/authprovider";
import DashboardProvider from "@/providers/dashboardprovider";
import GlobalModuleProvider from "@/providers/globalmoduleprovider";
import GlobalUserProvider from "@/providers/globaluserprovider";
import ItemLevelProvider from "@/providers/itemlevelprovider";
import ItemProvider from "@/providers/itemprovider";
import MatrixProvider from "@/providers/matrixprovider";
import OrganizationProvider from "@/providers/organizationprovider";
import StoreProvider from "@/providers/storeprovider";
import SaleProvider from "@/providers/saleprovider";
import PropositionProvider from "@/providers/propositionprovider";
import CustomerProvider from "@/providers/customerprovider";
import EntityProvider from "@/providers/entityprovider";
import SupplierProvider from "@/providers/supplierprovider";
import PurchaseProvider from "@/providers/purchaseprovider";
import POSProvider from '@/providers/posprovider';
import PriceListProvider from '@/providers/pricelistprovider';
import PrinterProvider from '@/providers/printerprovider';
import CurrencyProvider from '@/providers/currencyprovider';
import DiscountReasonProvider from "@/providers/discountreasonprovider";
import StoreTransferFlowProvider from "@/providers/storetransferflowprovider";
import JournalProvider from "@/providers/journalprovider";
import CountryProvider from '@/providers/countryprovider';
import StoreTransferDirectProvider from "@/providers/storetransferdirectprovider";
import StorePrinterProvider from '@/providers/storeprinterprovider';
import ZoneProvider from '@/providers/zoneprovider';
import PrinterModelProvider from '@/providers/printermodelprovider';

export default function useProvider() {
    const auth = new AuthProvider();
    const country = new CountryProvider();
    const currency = new CurrencyProvider();
    const customer = new CustomerProvider();
    const dashboard = new DashboardProvider();
    const discountReason = new DiscountReasonProvider();
    const entity = new EntityProvider();
    const globalModule = new GlobalModuleProvider();
    const globalUser = new GlobalUserProvider();
    const item = new ItemProvider();
    const itemLevel = new ItemLevelProvider();
    const journal = new JournalProvider();
    const matrix = new MatrixProvider();
    const organization = new OrganizationProvider();
    const pos = new POSProvider();
    const priceList = new PriceListProvider();
    const printer = new PrinterProvider();
    const printerModel = new PrinterModelProvider();
    const proposition = new PropositionProvider();
    const purchase = new PurchaseProvider();
    const sale = new SaleProvider();
    const store = new StoreProvider();
    const storePrinter = new StorePrinterProvider();
    const storeTransferFlow = new StoreTransferFlowProvider();
    const storeTransferDirect = new StoreTransferDirectProvider();
    const supplier = new SupplierProvider();
    const zone = new ZoneProvider();

    return {
        country,
        dashboard,
        globalUser,
        globalModule,
        organization,
        matrix,
        item,
        itemLevel,
        store,
        storePrinter,
        pos,
        auth,
        sale,
        proposition,
        customer,
        entity,
        supplier,
        purchase,
        priceList,
        printer,
        printerModel,
        currency,
        discountReason,
        storeTransferFlow,
        storeTransferDirect,
        journal,
        zone
    };
}