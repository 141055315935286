import BaseProvider from '@/providers/baseprovider';
import PrinterModel from '@/models/printermodel';
import {PagedParams} from '@/models/paged/pagedparams';
import PagedPrinterModels from '@/models/paged/pagedprintermodels';

export default class PrinterModelProvider extends BaseProvider {
    public async fetchPrinterModels(payload?: {
        printerModelIds?: number[]
    }): Promise<PrinterModel[]> {
        let p: String = '';
        p = p.addParams("printerModelIds", payload?.printerModelIds, true);
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV1()).get(`printermodels${p}`);

        return response.data as PrinterModel[];
    }

    public async fetchPagedPrinterModels(
        pagedParams: PagedParams
    ): Promise<PagedPrinterModels> {
        let p: String = '';
        p = p.addPagedParams(pagedParams, {
            pageNumber: 1,
            pageSize: 25,
            sortField: 'name',
            sortOrder: 'asc'
        });
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV1()).get(`printermodels${p}`);

        return new PagedPrinterModels(response?.data);
    }
}